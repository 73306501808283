import { useNavigate } from "react-router-dom";
import { Button, Container, Stack } from "@mui/material";
import { InitiativeOverview } from "../common/overview/InitiativeOverview";
import { InitiativeCategory } from "../common/category/InitiativeCategory";
import { Links } from "../common/links/Links";
import { InitiativeLocation } from "../common/location/Location";
import { Dates } from "../common/dates/Dates";
import { Collaboration } from "../common/collaboration/Collaboration";
import { Team } from "../common/team/Team";
import { ManageInitiativeButton } from "../common/ManageInitiativeButton";
import { PageContainer } from "../common/PageContainer";
import { SubnavigationBar } from "@/components";
import { ROUTES } from "@/config";
import { useCalculatePaddingTop } from "@/hooks";
import LightbulbRoundedIcon from '@mui/icons-material/LightbulbRounded';

export const CreateInitiative = () => {
  const navigate = useNavigate();
  const paddingTop = useCalculatePaddingTop();

  return (
    <>
      <SubnavigationBar 
        breadcrumbs={[
          { label: "Initiatives", href: ROUTES.initiatives, icon: <LightbulbRoundedIcon /> },
          { label: "Create", href: ROUTES.create_initiative },
        ]}
        actions={
          <Stack direction="row" alignItems="center" spacing={2}>
            <Button onClick={() => navigate(ROUTES.initiatives)} variant="outlined" color="secondary">
              Cancel
            </Button>
            <ManageInitiativeButton method="POST" />
          </Stack>
        }
      />
      <PageContainer sx={{pt: paddingTop}}>
        <Container className="container">
          <InitiativeOverview pageTitle="Create a new initiative" />
          <InitiativeCategory />
          <Links />
          <InitiativeLocation />
          <Dates />
          <Collaboration />
          <Team isCreatePage={true} />
        </Container>
      </PageContainer>
    </>
  );
};
