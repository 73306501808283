import { useState } from "react";
import {
  Container,
  Stack,
  Grid,
  Typography,
  Dialog,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { UserAutocomplete } from "../common/UserAutoComplete";
import { AssignSparxForm } from "../common/AssignSparxForm";
import { SaveButton } from "../common/SaveButton";
import { useSparxState } from "@/state";
import { ROUTES } from "@/config";
import { SuccessDialog } from "../common/SuccessDialog";
import { useBalance } from "@/hooks/api";
import { useCalculatePaddingTop } from "@/hooks";
import { BalanceCard } from "../BalanceCard";
import { LeaveMessage } from "@/pages/common/sparx/LeaveMessage";
import { SubnavigationBar } from "@/components";
import AutoAwesomeRoundedIcon from '@mui/icons-material/AutoAwesomeRounded';


export const DelegateSparx = () => {
  const paddingTop = useCalculatePaddingTop();
  useBalance();
  const { activity, setReset, isValid } = useSparxState();
  const [openDialog, setOpenDialog] = useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const onClose = () => setOpenDialog(false);

  const handleDone = () => {
    setOpenDialog(true);
    setReset();
  };

  return (
    <>
      <SubnavigationBar 
        breadcrumbs={[
          { label: "Sparx", href: ROUTES.sparx, icon: <AutoAwesomeRoundedIcon /> },
          { label: "Delegate Sparx", href: ROUTES.delegate_sparx },
        ]}
        actions={
          <SaveButton
          title="Delegate Sparx"
          disabled={activity?.message === undefined || !activity.message || !isValid()}
          onDone={handleDone}
        />
        }
      />
      <Container>
        <Grid container spacing={3} sx={{mt: paddingTop}}>
          <Grid item xs={12} md={8}>
            <Stack spacing={2}>
              <Typography variant="h6">Select Delegate</Typography>
              <UserAutocomplete type="DELEGATION" />
              <AssignSparxForm />
            </Stack>
            <Stack spacing={2} pt={6}>
              <LeaveMessage allowPostToShoutouts={false} isRequiredField />
            </Stack>
          </Grid>
          <Grid item xs={12} md={4}>
            <BalanceCard />
          </Grid>
        </Grid>
        <Dialog
          maxWidth="sm"
          open={openDialog}
          fullScreen={fullScreen}
          onClose={onClose}
          sx={{
            "& .MuiDialog-paperFullScreen": {
              borderRadius: theme.spacing(0),
            },
          }}
        >
          <SuccessDialog
            title="Sparx Delegated!"
            subTitle="Your delegatee will be notified about the delegation."
            onClose={onClose}
          />
        </Dialog>
      </Container>
    </>
  );
};
