import { useMemo } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Stack,
  Typography,
} from "@mui/material";
import SendRoundedIcon from '@mui/icons-material/SendRounded';
import { LeaveMessage } from "@/pages/common/sparx/LeaveMessage";
import { useBulkSparxState, useSparxState, useShoutsState, useUserState, useSearchState } from "@/state";
import { Balance } from "@/components";
import { DistributionContent } from "./DistributionContent";
import { useSendTokens, useShoutout } from "@/hooks/api";
import { toActivityBodyRequest, toShoutout } from "@/utils";
import { CategoriesSelector } from "@/pages/Sparx/common/CategoriesSelector.tsx";

type Props = {
  onSuccess: () => void;
};

export const DistributionModal = ({ onSuccess }: Props) => {
  const { userData } = useUserState();
  const { activity, shoutout, isPublic, setReset, categoryIds } = useSparxState();
  const {
    openDistributeModal,
    resetDistributed,
    sparx,
    distributedBalance,
    distributedError,
    reset
  } = useBulkSparxState();
  const { setState: setShoutoutState } = useShoutsState();
  const { trigger } = useSendTokens();
  const { trigger: triggerShoutouts } = useShoutout();
  const { categories: filteredCategories } = useSearchState();

  const filteredCategoryIds = useMemo(() => {
    return Object.keys(filteredCategories || {});
  }, [filteredCategories]);

  const onClose = () => {
    setReset();
    resetDistributed()
  };

  const onSendSparx = () => {
    if (activity && userData) {
      trigger({ data: toActivityBodyRequest(activity, userData, categoryIds) }).then(() => {
        setReset();
        reset();
        onSuccess();
      })

      if (shoutout)
        triggerShoutouts({ data: toShoutout(activity, isPublic) }).then(
          (shout) => {
            setShoutoutState({ shoutout: shout });
          }
        );
    }

  };

  return (
    <Dialog
      open={openDistributeModal}
      onClose={onClose}
      sx={{
        "& .MuiDialog-container": {
          "& .MuiPaper-root": {
            width: "100%",
            pt: 2,
          },
        },
      }}
      PaperProps={{
        style: {
          backgroundColor: "white",
        },
      }}
    >
      <DialogTitle textAlign="center">Send Sparx</DialogTitle>
      <DialogContent>
        <DialogContentText textAlign="center" mb={2}>
          Please choose the Sparx amount you wish to award for this contribution. 
          Optionally, you can also leave a message for the recipient.
        </DialogContentText>
        <Stack spacing={2} pb={3}>
          <Typography fontWeight={700}>Select Category</Typography>
          <CategoriesSelector initialCategoryIds={filteredCategoryIds} />
        </Stack>
        <DistributionContent />
        <Stack spacing={2} pt={3}>
          <LeaveMessage />
        </Stack>
      </DialogContent>
      <DialogActions sx={{ display: "flex" }}>
        <Balance
          balance={distributedBalance}
          sparx={sparx}
          sx={{ mr: "auto" }}
        />
        <Button variant="outlined" color="secondary" onClick={onClose}>
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          endIcon={<SendRoundedIcon />}
          onClick={onSendSparx}
          disabled={!!distributedError || categoryIds.length === 0}
        >
          Send Sparx
        </Button>
      </DialogActions>
    </Dialog>
  );
};
