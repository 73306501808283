import { DirectionFilterEnum, OwnershipFilterEnum } from "@/interface";
import { OwnershipData, emptyStateFields, EmptyStateFields, DEFAULT_EMPTY_STATE } from "./EmptyStateCopy";

export const fetchEmptyStateFields = (
  direction: DirectionFilterEnum,
  ownership: OwnershipFilterEnum,
  isWhale: boolean
): EmptyStateFields => {
  const userType = isWhale ? "whale" : "shrimp";
  const userTypeData = emptyStateFields[userType as keyof typeof emptyStateFields];

  // Get the data for the given DirectionFilterEnum type, falling back to the default state
  const directionData = userTypeData?.[direction];
  if (!directionData) {
    return DEFAULT_EMPTY_STATE;
  }

  // For shrimp (which doesn't have ownership-specific levels), return the direction data
  if (userType === "shrimp") {
    return directionData as EmptyStateFields;
  }

  // For whale, return ownership-specific data or fallback to default
  const ownershipData = (directionData as OwnershipData)?.[ownership];
  return ownershipData || DEFAULT_EMPTY_STATE;
};
