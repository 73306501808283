
import { useNavigate } from "react-router-dom"
import SendRoundedIcon from '@mui/icons-material/SendRounded';
import { NoDataMessage } from "@/components"
import { ROUTES } from "@/config"
import { useTransactionsState, useSparxState, useUserState } from "@/state";
import { fetchEmptyStateFields } from "./utils";
import { OwnershipFilterEnum } from "@/interface";
import { MIN_DELEGATION_TOKENS } from "@/constants";

type Props = {
  cycleName: string;
  isCurrentCycle: boolean;
}

export const EmptyCycleCard = ({cycleName, isCurrentCycle } : Props) => {
  const navigate = useNavigate();
  const { sparx } = useSparxState();
  const { userData } = useUserState();
  const { selectedDirectionFilter, selectedOwnershipFilter } = useTransactionsState();
  const isWhale = userData?.isWhale ?? false;
  const { title, subtitle, button } = fetchEmptyStateFields(selectedDirectionFilter, selectedOwnershipFilter, isWhale);
  
  const onClick = () => selectedOwnershipFilter === OwnershipFilterEnum.DELEGATED
  ? navigate(ROUTES.delegate_sparx)
  : navigate(ROUTES.send_sparx);

  const disableButton = selectedOwnershipFilter === OwnershipFilterEnum.DELEGATED 
  ? (sparx?.balance ?? 0) < MIN_DELEGATION_TOKENS
  : (sparx?.balance ?? 0) === 0;

  return (
    isCurrentCycle ? (
      <NoDataMessage
        colSpan={5}
        title={title}
        message={subtitle}
        buttonText={button}
        noPadding
        buttonClick={onClick}
        endIcon={<SendRoundedIcon />}
        buttonVariant="text"
        buttonDisabled={disableButton}
      />
    ) :
    (<NoDataMessage
        colSpan={5}
        title={"No transactions"}
        message={`You didn't spend or receive Sparx in ${cycleName}`}
        noPadding
      />)
  )
}