import { useMemo, useEffect } from "react";
import {
  Container,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import { SpentTransactions } from "./spent";
import { ReceivedTransactions } from "./received";
import { AllTransactions } from "./all";
import { useTransactionsState, useUserState } from "@/state";
import { Filter } from "@/components";
import {convertEnumToFilterOptions, extractTransactionsTableData, createFilterObject} from "@/utils";
import { useTransactions } from "@/hooks/api";

import { DirectionFilterEnum, OwnershipFilterEnum, FilterOption, KeyValuePair } from "@/interface";

export const Transactions = () => {
  const { userData } = useUserState();
  const isWhale = userData?.isWhale;
  const { data: receivedTransactions, mutate: mutateReceivedTransactions } = useTransactions(DirectionFilterEnum.RECEIVED, OwnershipFilterEnum.DELEGATED);
  const { data: spentTransactions, mutate: mutateSpentTransactions } = useTransactions(DirectionFilterEnum.SPENT, OwnershipFilterEnum.ALL);
  const { selectedDirectionFilter, selectedOwnershipFilter, setState } = useTransactionsState()
  const filterOptionsDirection: FilterOption[] = convertEnumToFilterOptions(DirectionFilterEnum);

  useEffect(() => {
    if (receivedTransactions && spentTransactions) {
      const spent = extractTransactionsTableData(spentTransactions, DirectionFilterEnum.SPENT);
      const received = extractTransactionsTableData(receivedTransactions, DirectionFilterEnum.RECEIVED);
      setState({ spentTransactions: spent, receivedTransactions: received });
    }
  }, [receivedTransactions, spentTransactions, mutateSpentTransactions, mutateReceivedTransactions, setState]);

  useEffect(() => {
    if (selectedOwnershipFilter === OwnershipFilterEnum.SENT) {
      setState({ selectedDirectionFilter: DirectionFilterEnum.SPENT });
    }
  }, [selectedOwnershipFilter, setState]);

  const filterOptionsOwnership = useMemo(() => {
    let options = convertEnumToFilterOptions(OwnershipFilterEnum);
    if (selectedDirectionFilter === DirectionFilterEnum.RECEIVED) {
      setState({ selectedOwnershipFilter: OwnershipFilterEnum.DELEGATED });
      options = [];
    }
    return options;
  }, [selectedDirectionFilter, setState]);

  const handleDirectionFilterSelect = (option: KeyValuePair) => {
    setState({ selectedDirectionFilter: option.value as DirectionFilterEnum, selectedOwnershipFilter: OwnershipFilterEnum.ALL });
  };

  const handleOwnershipFilterSelect = (option: KeyValuePair) => {
    setState({ selectedOwnershipFilter: option.value as OwnershipFilterEnum });
  };

  const FilteredTransactionsTable = 
    selectedDirectionFilter === DirectionFilterEnum.RECEIVED ? (
        <ReceivedTransactions mutate={mutateReceivedTransactions} />
    ) : selectedDirectionFilter === DirectionFilterEnum.SPENT ? (
        <SpentTransactions mutate={mutateSpentTransactions} />
    ) : (
        <AllTransactions 
            mutate={() => {
                mutateReceivedTransactions();
                mutateSpentTransactions();
            }}
        />
    );

  return (
    <Stack spacing={{ xs: 2, md: 6 }}>
      <Paper
        elevation={0}
        sx={{
          padding: 3,
          bgcolor: (theme) => theme.palette.background.default,
          border: "1px solid",
          borderColor: (theme) => theme.palette.divider,
          overflow: "hidden",
        }}
      >
        <Stack direction="column" spacing={2}>
          <Container sx={{ position: "relative", paddingY: 2 }}>
            <Typography
              variant="h6"
              sx={{
                position: "absolute",
                left: 0,
                top: "50%",
                transform: "translateY(-50%)",
              }}
            >
              Transactions
            </Typography>
          </Container>
          <Stack direction="row" spacing={2}>
            <Filter
              label={`${selectedDirectionFilter.charAt(0).toUpperCase() + selectedDirectionFilter.slice(1).toLowerCase() + (selectedDirectionFilter === "all" ? ' Transactions': '')}`}
              options={selectedOwnershipFilter === OwnershipFilterEnum.SENT ? [] : filterOptionsDirection}
              selected={createFilterObject(selectedDirectionFilter, DirectionFilterEnum)}
              onSelect={handleDirectionFilterSelect}
            />
            {isWhale && <Filter
              label={`${selectedOwnershipFilter.charAt(0).toUpperCase() + selectedOwnershipFilter.slice(1).toLowerCase() + (selectedOwnershipFilter === "all" ? ' Types': '')}`}
              options={selectedDirectionFilter === DirectionFilterEnum.RECEIVED ? [] : filterOptionsOwnership}
              selected={createFilterObject(selectedOwnershipFilter, OwnershipFilterEnum)}
              onSelect={handleOwnershipFilterSelect}
            />}
          </Stack>
        </Stack>
      {FilteredTransactionsTable}
      </Paper>
    </Stack>
  );
};