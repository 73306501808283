import { create } from "zustand";
import {
  Activity,
  Contributor,
  SparxBalance,
  Transaction,
  TransactionType,
  UserData,
} from "@/interface";
import { computeActivityTotalAmount, updateBalance } from "@/utils";
import { SparxTransactionEnum } from "@/enums";
import { MAX_WHALE_TX_TOKEN_LIMIT } from "@/constants";

interface SparxState {
  sparx?: SparxBalance;
  balance: number;
  setBalance: (sparx: SparxBalance) => void;

  // Current activity ( send | edit )
  activity?: Activity;
  activityTotalAmount: number;
  setActivity: (activity?: Activity) => void;
  setActivityMessage: (message: string) => void;

  // Current transaction id ( delete )
  transactionId?: string;
  setTransaction: (transactionId?: string) => void;

  shoutout: boolean;
  setShoutout: (shoutout: boolean) => void;
  isPublic: boolean;
  setShoutoutPrivacy: (isPublic: boolean) => void;

  setReceivers: (receivers: UserData[], type?: TransactionType) => void;
  setAmount: (transactionId: string, amount: number | string) => void;
  // Update the receivers list from the summary page
  setBulkReceivers: (contributors: Contributor[]) => void;
  setBulkAmount: (amount: number | string) => void;

  setReset: () => void;
  // Check if form is valid
  isValid: () => boolean;

  categoryIds: string[];
  setCategoryIds: (categoryIds: string[]) => void;
}
const useSparxState = create<SparxState>((set, get) => ({
  shoutout: false,
  isPublic: true,
  balance: 0,
  activityTotalAmount: 0,
  categoryIds: [],

  setShoutout: (shoutout: boolean) => {
    set({ shoutout });
  },

  setShoutoutPrivacy: (isPublic: boolean) => {
    set({ isPublic });
  },

  setActivityMessage(message) {
    let { activity, shoutout } = get();

    if (activity) {
      activity = { ...activity, message };
    }
    if (!message) {
      shoutout = false;
    }

    set({ activity, shoutout });
  },

  setBalance: (sparx: SparxBalance) => {
    const balance = sparx?.balance ?? 0;
    set({ sparx, balance });
  },

  setActivity: (a?: Activity) => {
    const activityTotalAmount = computeActivityTotalAmount(a);
    const activity: Activity = {
      ...a,
      message: a?.message ?? "",
      transactions: a?.transactions ?? [],
    };
    set({ activity, activityTotalAmount });
  },

  setTransaction(transactionId) {
    set({ transactionId });
  },

  setReceivers: (
    receivers: UserData[],
    type = SparxTransactionEnum.ASSIGNMENT
  ) => {
    const { activityTotalAmount, sparx } = get();
    let { activity, balance } = get();

    const transactions =
      activity?.transactions.filter((tr) =>
        receivers.find((r) => r.id === tr.receiver.id)
      ) ?? [];

    receivers.forEach((receiver) => {
      if (!transactions.find((tr) => tr.receiver.id === receiver.id)) {
        transactions.push({
          id: crypto.randomUUID(),
          receiver,
          type,
          amount: 1,
          error:
            balance - 1 < 0
              ? "The quantity entered exceeds the available Sparx budget."
              : undefined,
        });
      }
    });

    activity = { ...activity, transactions };

    // update balance
    balance = updateBalance(
      sparx,
      activityTotalAmount,
      computeActivityTotalAmount(activity)
    );

    set({ activity, balance });
  },

  setBulkReceivers: (contributors: Contributor[]) => {
    const { activityTotalAmount, sparx } = get();
    let { activity, balance } = get();

    const transactions: Transaction[] = [];

    contributors.forEach((contributor) => {
      transactions.push({
        id: crypto.randomUUID(),
        receiver: contributor.user,
        type: "ASSIGNMENT",
        amount: contributor.amount || 0,
      });
    });

    activity = { ...activity, transactions };

    // update balance
    balance = updateBalance(
      sparx,
      activityTotalAmount,
      computeActivityTotalAmount(activity)
    );

    set({ activity, balance });
  },

  setAmount: (transactionId: string, amount: number | string) => {
    const { activityTotalAmount, sparx, activity } = get();
    let { balance } = get();

    if (activity) {
      const index = activity?.transactions.findIndex(
        (tr) => tr.id === transactionId
      );
      activity.transactions[index].amount = amount;
      // Update balance
      balance = updateBalance(
        sparx,
        activityTotalAmount,
        computeActivityTotalAmount(activity)
      ); 

      // Setting errors for transactions
      activity.transactions.forEach((trx) => {
        const trxAmount = Number(trx.amount);
        trx.error = undefined;

        if (trxAmount < 1) {
          trx.error = "You must assign at least 1 Sparx.";
        } 

        if (trx.type === SparxTransactionEnum.ASSIGNMENT && trxAmount > MAX_WHALE_TX_TOKEN_LIMIT) {
          trx.error = `Please assign max ${MAX_WHALE_TX_TOKEN_LIMIT} Sparx per transaction.`;
        }
      });

      if (balance < 0) {
        const lastIndexInTransactions = activity.transactions.length - 1;
        activity.transactions[lastIndexInTransactions].error = "The total amount entered exceeds the available Sparx budget.";
      }
    }

    set({ activity, balance });
  },

  setBulkAmount: (amount: number | string) => {
    const { activityTotalAmount, sparx, activity } = get();
    let { balance } = get();
  
    if (activity && activity.transactions) {
      // Update the amount for each transaction
      const updatedTransactions = activity.transactions.map(transaction => ({
        ...transaction,
        amount: Number(amount), // Ensure the amount is a number
      }));
  
      // Update activity with the new transaction amounts
      const updatedActivity = { ...activity, transactions: updatedTransactions };
  
      // Recalculate balance based on updated transactions
      balance = updateBalance(
        sparx,
        activityTotalAmount,
        computeActivityTotalAmount(updatedActivity)
      );
  
      // Set the updated activity and balance in the state
      set({ activity: updatedActivity, balance });
    }
  },

  setReset() {
    set({
      shoutout: false,
      isPublic: true,
      activityTotalAmount: 0,
      activity: undefined,
      categoryIds: [],
      transactionId: undefined,
    });
  },

  isValid() {
    const { activity } = get();
    if (
      !activity ||
      activity.transactions.some(tr => 
        tr.error || !tr.amount || Number(tr.amount) < 1
      ) ||
      activity.transactions.length === 0
    ) {
      return false;
    }
    return true;
  },

  setCategoryIds: (categoryIds: string[]) => {
    set({categoryIds});
  },
}));

export { useSparxState };
