import { useMemo } from "react";
import { Stack } from "@mui/material";
import { DirectionFilterEnum, TransactionTableCycleData } from "@/interface";
import { useTransactionsState } from "@/state";
import { EmptyTransactionsCard } from "../common/EmptyTransactionsCard";
import { TransactionsTable } from "../common/TransactionsTable";
import { filterTransactions } from "@/utils";

type Props = {
  mutate: () => void
} 

export const AllTransactions = ({ mutate }: Props) => {
  const { receivedTransactions, spentTransactions, selectedOwnershipFilter } = useTransactionsState();
  
  const combinedTransactions = useMemo(() => {
    const transactions = [];
    const cycleMap = new Map();

    // Combine both arrays into the map to handle cycles
    for (const array of [receivedTransactions, spentTransactions]) {
        for (const cycle of array) {
            if (cycleMap.has(cycle.symbol)) {
                // If the cycle already exists, merge transactions
                const existingCycle = cycleMap.get(cycle.symbol);
                existingCycle.transactions.push(...cycle.transactions);
            } else {
                // If the cycle does not exist, add it to the map
                cycleMap.set(cycle.symbol, { ...cycle, transactions: [...cycle.transactions] });
            }
        }
    }

    // Convert map back to array
    for (const cycle of cycleMap.values()) {
      transactions.push(cycle);
    }

    return transactions;
  }, [receivedTransactions, spentTransactions]);

  const filteredTransactions: TransactionTableCycleData[] = useMemo(() => { 
    return filterTransactions(combinedTransactions, selectedOwnershipFilter);
  }, [combinedTransactions, selectedOwnershipFilter]);

  if (!filteredTransactions || filteredTransactions?.length === 0) return <EmptyTransactionsCard directionType={DirectionFilterEnum.ALL} ownershipType={selectedOwnershipFilter}/>;

  return (
    <Stack pt={2}>
      <TransactionsTable cycleTransactions={filteredTransactions} transactionsType={DirectionFilterEnum.ALL} mutate={mutate}/>
    </Stack>
  );
};