import { createBrowserRouter, Navigate } from "react-router-dom";
import { ROUTES, URL_FRAGMENTS } from "../config";
import {
  AllocationPage,
  AuthProvider,
  CreateInitiative,
  EditInitiative,
  Details,
  InitiativesOverview,
  Layout,
  Activities,
  MySparx,
  OktaCallback,
  ReviewContributions,
  Rewards,
  Shoutouts,
} from "../pages";
import { SendSparx } from "@/pages/Sparx/send";
import { EditSparx } from "@/pages/Sparx/edit";
import { DelegateSparx } from "@/pages/Sparx/delegate";

const routes = createBrowserRouter([
  {
    path: `${ROUTES.root}`,
    element: <AuthProvider />,
    children: [
      {
        element: <Layout />,
        children: [
          { index: true, element: <Shoutouts /> },
          {
            path: "/shoutouts",
            element: <Navigate to="/" replace={true} />,
          },
          {
            path: `${ROUTES.initiatives}`,
            element: <InitiativesOverview />,
          },
          {
            path: `${ROUTES.initiative}/:id`,
            element: <Details />,
          },
          {
            path: `${ROUTES.my_activity}`,
            element: <Activities />,
          },
          {
            path: `${ROUTES.create_initiative}`,
            element: <CreateInitiative />,
          },
          {
            path: `${ROUTES.edit_initiative}`,
            element: <EditInitiative />,
          },
          {
            path: `${ROUTES.allocation}`,
            element: <AllocationPage />,
          },
          {
            path: `${ROUTES.sparx}/:sub?`,
            element: <MySparx />,
          },
          {
            path: `${ROUTES.sparx}/send?`,
            element: <SendSparx />,
          },
          {
            path: `${ROUTES.sparx}/contributions/review`,
            element: <ReviewContributions />,
          },
          {
            path: `${ROUTES.sparx}/edit?`,
            element: <EditSparx />,
          },
          {
            path: `${ROUTES.sparx}/delegate`,
            element: <DelegateSparx />,
          },
          {
            path: `${ROUTES.initiative_review}/:id`,
            element: <Details isReview />,
          },
          {
            path: `${ROUTES.initiative_preview}/:id`,
            element: <Details isPreview />,
          },
          {
            path: `${ROUTES.rewards}`,
            element: <Rewards />,
          },
        ],
      },
    ],
  },
  {
    path: URL_FRAGMENTS.AUTH_CALLBACK_FRAGMENT,
    element: <OktaCallback />,
  },
]);

export { routes };
