import {
  Card,
  CardContent,
  Box,
  Typography,
  Button,
  Stack,
} from "@mui/material";
import SendRoundedIcon from '@mui/icons-material/SendRounded';
import Image from "@/assets/EmptyState.png";
import { DirectionFilterEnum, OwnershipFilterEnum } from "@/interface";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "@/config";
import { useSparxState, useUserState } from "@/state";
import { fetchEmptyStateFields } from "./utils";

type Props = {
  directionType: DirectionFilterEnum;
  ownershipType: OwnershipFilterEnum; 
};

export const EmptyTransactionsCard = ({ directionType, ownershipType }: Props) => {
  const { sparx } = useSparxState();
  const navigate = useNavigate();
  const { userData } = useUserState();
  const isWhale = userData?.isWhale ?? false;
  const { title, subtitle, button } = fetchEmptyStateFields(directionType, ownershipType, isWhale);

  const onClick = () =>
    ownershipType === OwnershipFilterEnum.DELEGATED
      ? navigate(ROUTES.delegate_sparx)
      : navigate(ROUTES.send_sparx);

  const showButton = (directionType === DirectionFilterEnum.RECEIVED ? isWhale : true) && button != null;

  return (
    <Stack spacing={{ xs: 2, md: 3 }}>
      <Card
        elevation={0}
        sx={{
          height: 272,
          width: "100%",
          backgroundImage: `url(${Image})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          border: 0,
        }}
      >
        <CardContent>
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            height={272}
          >
            <Typography variant="h6" component="h1" gutterBottom>
              {title}
            </Typography>
            <Typography color="body2">{subtitle}</Typography>
            {showButton && <Box mt={2}>
              <Button
                variant="text"
                color="primary"
                disabled={sparx?.balance === 0}
                endIcon={<SendRoundedIcon />}
                onClick={onClick}
              >
                {button}
              </Button>
            </Box>}
          </Box>
        </CardContent>
      </Card>
    </Stack>
  );
};
