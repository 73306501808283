import { UserData } from ".";

export interface PoolData {
  pools: Pool[];
}

export interface Pool {
  id: number;
  balance: number;
  delegated: boolean;
  allocatedFor?: UserData;
}

export interface PoolTransaction {
  id: number;
  recipient: UserData;
  delegated: boolean;
  delegator: UserData;
  createdAt: string;
  message: string;
  amount: number;
  symbol: string;
}

export type PoolTransactionsData = PoolTransaction[];

export interface TransactionRequest {
  recipientId: number;
  amount: number;
  message?: string;
}
export interface TransactionUpdateRequest {
  transactionId: number;
  message: string;
}

export interface Cycle {
  symbol: string;
  active: boolean;
  name: string;
  startDate: string;
  endDate: string;
  mdpTokenAllocation: number;
  peerTokenAllocation: number;
  bootstrapped: boolean;
}

export interface SparxBalance {
  balance: number;
  cycle: Cycle;
}

export type TransactionType = "ASSIGNMENT" | "DELEGATION" | "ALLOCATION";

export interface Transaction {
  id: string;
  amount: number | string;
  sender?: UserData;
  receiver: UserData;
  createdAt?: string;
  type: TransactionType;
  error?: string;
}

export interface Activity {
  id?: string;
  message?: string;
  transactions: Transaction[];
  categoryIds?: string[];
}

export interface ActivityBodyRequest {
  id?: string;
  message?: string;
  categoryIds?: string[];
  transactions: {
    id?: string;
    amount: number;
    sender: string;
    receiver: string;
    type: TransactionType;
  }[];
}

export enum ActivitiesType {
  SENT,
  DELEGATED,
}

export enum DirectionFilterEnum {
  ALL = "all",
  SPENT = "spent",
  RECEIVED = "received",
}

export enum OwnershipFilterEnum {
  ALL = "all",
  SENT = "sent",
  DELEGATED = "delegated",
}

export interface Transactions {
  id: string;
  amount: number;
  type: TransactionType;
  createdAt: string;
  activity: Partial<Activity>;
  sender: Partial<UserData>;
  receiver: Partial<UserData>;
}

export interface TransactionsByCycle {
  symbol: string;
  name: string;
  startDate: string;
  endDate: string;
  state: string;
  transactions: Transactions[];
}

export interface ExtendedTransaction extends Transactions {
  displayedUser: Partial<UserData>;
}
export interface TransactionTableCycleData {
  symbol: string;
  name: string;
  endDate: string;
  state: string;
  transactions: ExtendedTransaction[];
}